/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    object, string,
} from 'prop-types';
import AddDonationWidget from './AddDonationWidget';

const DonationItem = ({
    data, pageProcess,
}) => {
    const content = data?.findContentPage?.content?.entries?.[0];
    if (!content) {
        return null;
    }
    return (
        <AddDonationWidget content={content} pageProcess={pageProcess} />
    );
};

DonationItem.propTypes = {
    pageProcess: string.isRequired,
    data: object,
};

DonationItem.defaultProps = {
    data: {},
};

export default DonationItem;
