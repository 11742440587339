/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    bool, func, string,
} from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import ReactMarkdown from 'react-markdown';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    closeButton: {
        marginLeft: 'auto',
        padding: 5,
    },
    content: {
        '& h1,h2,h3,h4,h5,h6': {
            margin: 0,
        },
    },
}));

const GraphqlIcBannerDetail = ({
    open, setClose, popInfo, title,
}) => {
    const classes = useStyles();
    return (
        <div>
            <Dialog
                fullWidth
                maxWidth="xs"
                open={open}
            >
                <IconButton data-testid="close-button" className={classes.closeButton} aria-label="close" onClick={() => setClose(title)}>
                    <CloseIcon />
                </IconButton>
                <DialogContent className={classes.content}>
                    <ReactMarkdown source={popInfo} escapeHtml={false} />
                </DialogContent>
            </Dialog>
        </div>
    );
};
GraphqlIcBannerDetail.propTypes = {
    setClose: func.isRequired,
    open: bool.isRequired,
    popInfo: string.isRequired,
    title: string.isRequired,
};

export default GraphqlIcBannerDetail;
