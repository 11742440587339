/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    arrayOf, number, shape, string,
} from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Product from '../../GraphqlSimpleProduct/GraphqlSimpleProduct';

// handles granular attribute values for different presentation types
const themes = {
    productImageContiner: {
        default: {
            border: '10px solid',
            borderBottom: 'none',
            mobileBorder: '8px solid',
        },
        variant_a: {
            border: 'none',
            borderRadius: '12px',
        },
    },
};

const useStyles = makeStyles((theme) => ({
    productWrapper: ({ variantType }) => ({
        padding: '10px',
        [theme.breakpoints.down(601)]: {
            padding: '4px',
        },
        '& img': {
            border: themes?.productImageContiner[variantType]?.border || '10px solid',
            borderColor: variantType === 'default' ? `${theme?.palette?.white}` : '',
            borderBottom: themes?.productImageContiner[variantType]?.borderBottom || 'none',
            borderRadius: themes?.productImageContiner[variantType]?.borderRadius || 'none',
        },
    }),
}));

const IcSubcollectionPanel = ({
    productList, variantType,
}) => {
    const classes = useStyles({ variantType });

    return (
        <div className={classes.productWrapper}>
            <Product
                name={productList?.name}
                prices={productList?.prices}
                partNumber={productList?.partNumber}
                image={productList?.image}
                url={productList?.seo?.url}
                skuPriceRange={productList?.skuPriceRange}
                isSubcollectionBanner
            />
        </div>
    );
};

IcSubcollectionPanel.propTypes = {
    productList: shape({
        name: string,
        partNumber: string,
        id: string,
        image: shape({
            name: string,
            path: string,
            snipe: string,
        }),
        prices: arrayOf(shape({
            type: string,
            value: number,
        })),

    }),
    variantType: string.isRequired,
};

IcSubcollectionPanel.defaultProps = {
    productList: {},
};

export default IcSubcollectionPanel;
