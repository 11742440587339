/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    number,
    shape,
} from 'prop-types';
import useUIDQueryConditionally from '../../../../../../../../helpers/hooks/useUIDQueryConditionally';
import GenericSkeleton from '../../../../../../GraphqlSkeletonComponents/GenericSkeleton';
import IcSubcollectionHeader from './IcSubcollectionHeader';

const ICSubcollectionBanner = ({
    data: icBannerBlock = {},
}) => {
    const { data, loading } = useUIDQueryConditionally({
        data: icBannerBlock,
        newDataSelector: (dta) => dta?.findContentByUID?.content?.entry || data,
    });

    if (loading) {
        return <GenericSkeleton height={300} />;
    }
    if (!data) return null;

    return <IcSubcollectionHeader subcollectionData={data} />;
};

ICSubcollectionBanner.propTypes = {
    data: shape({
        number_of_spots_to_use: number,
        position_desktop: number,
        position_mobile: number,

    }),
};
ICSubcollectionBanner.defaultProps = {
    data: {},
};
export default ICSubcollectionBanner;
