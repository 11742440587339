/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import {
    object, string,
} from 'prop-types';
import DonationBuilder from '../../ProductQuickview/partials/DonationItemBuilder/DonationBuilder';

const IcBannerDonationModal = ({ url, classes }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 720,
        height: 'auto',
        bgcolor: '#FFF',
        boxShadow: 24,
        padding: '32px 24px',
        '@media screen and (max-width: 700px)': {
            width: '95%',
        },
    };

    return (
        <>
            <button data-testid="openDonation" type="button" onClick={handleOpen} className={classes.icBannerDonationButton}>
                <img alt="Donation" src={url} className={classes.productImage} />
            </button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box data-testid="donationBox" sx={style}>
                    <CloseIcon
                        onClick={handleClose}
                        style={{
                            cursor: 'pointer', position: 'absolute', top: 10, right: 10,
                        }}
                    />
                    <DonationBuilder />
                </Box>
            </Modal>
        </>
    );
};

IcBannerDonationModal.propTypes = {
    url: string.isRequired,
    classes: object.isRequired,
};

export default IcBannerDonationModal;
