/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';

import { func, object, string } from 'prop-types';
import IcBanner from './GraphqlInCollectionBanner/IcBanner';
import ICSubcollectionBanner from './GraphqlInCollectionBanner/SubCollectionBanner/ICSubcollectionBanner';

const GraphqlIcBanner = ({ icBannerBlock = {}, presentationFamily, handleOpenPassportModal }) => {
    if (icBannerBlock.reference?.[0]?._content_type_uid === 'incollection_subcollection') {
        return  <ICSubcollectionBanner data={icBannerBlock} />;
    }
    return (
        <IcBanner
            icBannerBlock={icBannerBlock}
            presentation_family={presentationFamily}
            handleOpenPassportModal={handleOpenPassportModal}
        />
    );
};

GraphqlIcBanner.propTypes = {
    icBannerBlock: object,
    presentationFamily: string.isRequired,
    handleOpenPassportModal: func,
};
GraphqlIcBanner.defaultProps = {
    icBannerBlock: {},
    handleOpenPassportModal: () => {},
};

export default GraphqlIcBanner;
