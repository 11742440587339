/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    arrayOf, number, shape, string,
} from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useUIDSeed } from 'react-uid';
import Slider from 'react-slick';
import NavigationPaginate from '../../../../../../../ContentTypeComponents/Buttons/NavigationPaginate';
import IcSubcollectionPanel from './IcSubcollectionPanel';

// handles granular attribute values for different presentation types
const themes = {

    arrowSlider: {
        default: {
            top: '-40px',
            forwardArrowRight: '20px',
            backArrowRight: '70px',
            mobileBackArrowRight: '58px',
            mobileForwardArrowRight: '10px',
            navButtonDisabled: '1',
            backgroundColor: 'transparent',
        },
        variant_a: {
            top: '38%',
            forwardArrowRight: '-10px',
            navButtonDisabled: '0',
            backArrowLeft: '-10px',
            backgroundColor: '#F1EDEE',

        },
    },

};

const useStyles = makeStyles((theme) => ({
    mainSliderContainer: {
        width: '100%',
    },
    sliderContainer: ({ variantStyle }) => ({
        position: 'relative',
        width: '100%',
        '& .slick-list': {
            overflow: 'hidden',
            margin: '0 auto',
            width: '100%',
        },
        '& .slick-slide': {
            display: 'inline-block',
            padding: 3,
            verticalAlign: 'top',
        },
        '& .backwardArrow, & .forwardArrow': {
            cursor: 'pointer',
            top: themes?.arrowSlider[variantStyle]?.top || '-40px',
            width: 40,
            height: 40,
            position: 'absolute',
            backgroundColor: themes?.arrowSlider[variantStyle]?.backgroundColor || 'transparent',
        },
        '& .backwardArrow': {
            right: themes?.arrowSlider[variantStyle]?.backArrowRight || '70px',
            left: themes?.arrowSlider[variantStyle]?.backArrowLeft,
            [theme.breakpoints.down(630)]: {
                right: themes?.arrowSlider[variantStyle]?.mobileBackArrowRight,
            },
            zIndex: 998,
        },
        '& .forwardArrow': {
            right: themes?.arrowSlider[variantStyle]?.forwardArrowRight || '20px',
            [theme.breakpoints.down(630)]: {
                right: themes?.arrowSlider[variantStyle]?.mobileForwardArrowRight,
            },
        },
        '& .slick-disabled': {
            opacity: themes?.arrowSlider[variantStyle]?.navButtonDisabled || '',
            '& .back': {
                opacity: '0.1',
            },
            '& .forward': {
                opacity: '0.1',
            },
            borderColor: `${theme.palette.cms?.horizentalLineBorder || '#DEDEDE'} !important`,
            backgroundColor: '#00000008 !important',
            color: theme.palette.cms?.diabledBorderColor || '#DCDCDC',
            cursor: 'default',
        },
        '& .slick-dots': {
            listStyle: 'none',
            display: 'flex !important',
            padding: '0',
            justifyContent: 'center',
            margin: '0',
            '& li': {
                lineHeight: 1,
            },
            '& .slick-active': {
                '& div': {
                    background: theme.palette.cms?.white || 'rgb(47, 47, 47)',
                },
            },
            '& .slick-current': {
                '& div': {
                    paddingRight: 5,
                },
            },
        },
    }),
    dotNavigation: {
        width: 22,
        height: 2,
        cursor: 'pointer',
        display: 'inline-block',
        marginRight: 5,
        background: theme.palette.cms?.borderGrayColor || '#c0b9b9',
    },
}));

const IcSubcollectionSliderUI = ({
    productData, variantStyle,
}) => {
    if (!productData) return null;

    const classes = useStyles({ variantStyle });
    const seed = useUIDSeed();

    return (
        <div className={classes.mainSliderContainer} data-testid="ic_subcollection_v2">
            <Slider
                dots={false}
                speed={500}
                infinite={false}
                slidesToShow={2}
                slidesToScroll={2}
                nextArrow={<NavigationPaginate color="#333333" direction="forward" />}
                prevArrow={<NavigationPaginate color="#333333" direction="back" />}
                className={`carousel-container ${classes.sliderContainer}`}
                responsive={[
                    {
                        breakpoint: 601,
                        settings: {
                            dots: true,
                            slidesToShow: 1.5,
                            slidesToScroll: 1,
                            arrows: false,
                            infinite: false,
                            dotsClass: 'slick-dots slick-thumb',
                            className: `carousel-container ${classes.sliderContainer}`,
                            customPaging: () => <div className={classes.dotNavigation} />,
                        },
                    },
                ]}
            >
                {
                    productData?.map((product) => (
                        <div key={seed(product)} className={classes.productWrapper}>
                            <IcSubcollectionPanel productList={product} variantType={variantStyle} />
                        </div>
                    ))
                }
            </Slider>
        </div>
    );
};

IcSubcollectionSliderUI.propTypes = {
    productData: arrayOf(shape({
        name: string,
        partNumber: string,
        id: string,
        image: shape({
            name: string,
            path: string,
            snipe: string,
        }),
        prices: arrayOf(shape({
            type: string,
            value: number,
        })),

    })).isRequired,
    variantStyle: string,

};
IcSubcollectionSliderUI.defaultProps = {
    variantStyle: 'default',
};

export default IcSubcollectionSliderUI;
