/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose } from 'redux';
import { useUIDSeed } from 'react-uid';

const styles = (theme) => ({
    DonaminationBtn: {
        marginRight: '0.5rem',
        fontSize: '0.9em',
        [theme.breakpoints.down(960)]: {
            marginRight: 0,
        },
        '@media screen and (min-width: 300px) and (max-width: 960px)': {
            maxWidth: 150,
            marginRight: 4,
            marginBottom: 10,
        },
        display: 'inline',
        color: '#000000',
        backgroundColor: '#ffffff',
        fontWeight: 'bold',
        '& span': {
            textTransform: 'none',
        },
        '&:hover, &:active': {
            backgroundColor: 'none',
            color: 'none',
        },
        textTransform: 'none',
    },
    selectedDonaminationBtn: {
        border: '1px solid #000',
        textTransform: 'none',
    },
    textField: {
        '&&': {
            marginRight: '10px',
        },
    },
    rootBase: {
        fontSize: '1em',
    },
    inputBase: {
        fontSize: '1em',
    },
    '@media screen and (min-width: 300px) and (max-width: 960px)': {
        textField: {
            '&&': {
                marginRight: '10px',
                paddingBottom: '3px',
            },
        },
    },
    activeButton: {
        border: '1px solid #000',
        color: '#000000',
        '&:hover': {
            border: '1px solid #000',
            color: '#000000',
        },
        textTransform: 'none',
    },
});

const DonationSkuListItem = ({
    donationSkuList, title, showTextField,
    classes, handlesetValue, activeButton, fieldValue, handleOnChangeDenomination,
}) => {
    const seed = useUIDSeed();
    const returnData = donationSkuList?.map((sku) => {
        const keyIndex = `donation${seed}`;
        const keyBtnIndex = `btnDonation${seed}`;
        let donamination = '';
        const skuDonimation = sku?.skus?.denomination;
        if (Number.isNaN(Number(skuDonimation))) {
            donamination = skuDonimation.toLowerCase();
            donamination = donamination?.[0]?.toUpperCase() + donamination?.slice(1);
        } else {
            donamination = `$${parseFloat(skuDonimation || 0)}`;
        }
        if (showTextField && skuDonimation === 'OTHER') {
            return (
                <TextField
                    key={keyIndex}
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    InputProps={{ classes: { root: classes.rootBase, input: classes.inputBase } }}
                    style={{ width: 80 }}
                    value={fieldValue}
                    onChange={handleOnChangeDenomination(sku.skus, title)}
                />
            );
        }
        return (
            <Button
                key={keyBtnIndex}
                sx={{ marginRight: 10 }}
                variant="contained"
                color="default"
                className={
                    activeButton === donamination
                        ? `${classes.DonaminationBtn} ${classes.activeButton}`
                        : `${classes.DonaminationBtn}`
                }

                id={donamination}
                onClick={(e) => handlesetValue(e, sku?.skus, title, donamination)}
            >
                {donamination}
            </Button>
        );
    });
    return returnData;
};

export default compose(
    withStyles(styles),
)(DonationSkuListItem);
