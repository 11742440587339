/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    object, string, shape, arrayOf, func,
} from 'prop-types';
import Button from '@material-ui/core/Button';
import ReactMarkdown from 'react-markdown';
import DonationSkuListItem from './DonationSkuListItem';

import { addDonation } from '../../../../../../../../state/ducks/Common/ducks/Donation/Donation-Actions';
import { getDonationError } from '../../../../../../../../state/ducks/Common/ducks/Donation/Donation-Selectors';
import { trackEvent } from '../../../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

const styles = (theme) => ({
    productTitle: {
        fontSize: '22px',
        margin: '0 0 10px 0',
    },
    btn: {
        width: '100%',
        padding: '9px 7px',
        fontSize: '0.9em',
        display: 'inline',
        fontWeight: 'bold',
        textTransform: 'none',
    },
    btnDonate: {
        color: '#000000',
        backgroundColor: '#ffffff',
    },
    buttonDonateDisable: {
        color: '#ffffff',
        backgroundColor: '#000000',
        '&:hover, &:active': {
            backgroundColor: '#000000',
            color: '#ffffff',
        },
    },
    donationContainer: {
        display: 'flex',
        [theme.breakpoints.down(700)]: {
            flexDirection: 'column',
        },
    },
    donationImgContainer: {
        '& img': {
            display: 'block',
            width: '284px',
            height: '311px',
            [theme.breakpoints.down(700)]: {
                display: 'none',
            },
        },
    },
    donationContentContainer: {
        marginLeft: '24px',
        flex: 1,
        [theme.breakpoints.down(700)]: {
            marginLeft: 0,
        },
    },
    donationDesc: {
        fontSize: '13px',
    },
    donationAmountTitle: {
        marginTop: '16px',
    },
    donationSkuContainer: {
        marginBottom: '16px',
    },
    donationDonateButtonContainer: {
        width: '100%',
    },
});

const sortbyRank = (entries) => {
    const skus = [...entries];
    return skus?.sort((a, b) => ((a?.skus?.rank > b?.skus?.rank) ? 1 : -1));
};

const getPageURL = () => {
    if (typeof window !== 'undefined') {
        const pageUrl = window?.location?.href || '';
        if (pageUrl.indexOf('/payment') >= 0 || pageUrl.indexOf('/review-order') >= 0) {
            return true;
        }
    }

    return false;
};

const AddDonationWidget = ({
    classes, callDonationItem, callTrackEvent, content,
    pageProcess,

}) => {
    const [disableBtn, setdisableBtn] = useState(true);
    const [showTextField, setshowTextField] = useState(false);
    const [fieldValue, setFieldValue] = useState('');
    const [skuDetails, setSkuDetails] = useState({});
    const [activeButton, setActiveButton] = useState();
    const [roundup, setRoundup] = useState();
    const { description, imageurl } = content;
    const isReviewPageOrPaymentPage = getPageURL();
    const filteredSkuListArray = content?.skus?.filter((el) => el?.skus?.denomination !== 'ROUND');
    const donationSkuListArray = content?.skus;
    const skulistArray = isReviewPageOrPaymentPage ? donationSkuListArray : filteredSkuListArray;
    const donationSkuList = sortbyRank(skulistArray);

    const handleSubmit = () => {
        const currentSelectedSku = {
            sku_code: skuDetails?.value?.sku_code,
            denomination: skuDetails?.value?.denomination,
            title: skuDetails?.title,
            pageProcess,
        };
        if (currentSelectedSku.denomination?.toLowerCase() === 'round' && roundup) {
            currentSelectedSku.denomination = roundup;
        } else if (Number(currentSelectedSku.denomination) > 0) {
            currentSelectedSku.denomination = Number(currentSelectedSku.denomination).toFixed(2);
        }

        callTrackEvent({
            eventCategory: 'Checkout',
            eventAction: 'Add To Cart',
            eventLabel: 'Donations',
        });

        callDonationItem(currentSelectedSku);
    };

    const handlesetValue = (e, sku, title, denomination) => {
        let value = '';
        setActiveButton(denomination);
        setdisableBtn(false);
        setshowTextField(false);
        setRoundup('');
        if (sku?.denomination === 'OTHER') {
            setshowTextField(!showTextField);
            setRoundup('');
            setActiveButton('');
            setdisableBtn(true);
            setFieldValue('');
        }
        value = {
            sku_code: sku?.sku_code,
            denomination: sku?.denomination,
        };

        setSkuDetails({
            title,
            value,
        });
    };

    return (
        <div className={classes.donationContainer}>
            <div className={classes.donationImgContainer}><img alt="Donation" src={imageurl.href} /></div>
            <div className={classes.donationContentContainer}>
                <h2 className={classes.productTitle}>{imageurl.title}</h2>
                <div className={classes.donationDesc}>
                    <ReactMarkdown
                        source={description}
                        escapeHtml={false}
                    />
                </div>
                <hr />
                <h4 className={classes.donationAmountTitle}>
                    Donation Amount
                </h4>
                <div className={classes.donationSkuContainer}>
                    <DonationSkuListItem
                        donationSkuList={donationSkuList}
                        title={imageurl?.title}
                        activeButton={activeButton}
                        handlesetValue={handlesetValue}
                        showTextField={showTextField}
                        fieldValue={fieldValue}
                    />
                </div>
                <div className={classes.donationDonateButtonContainer}>
                    <Button
                        size="small"
                        variant="contained"
                        disabled={disableBtn}
                        className={`${classes.btn} ${disableBtn ? classes.btnDonate : classes.buttonDonateDisable}`}
                        onClick={handleSubmit}
                    > Donate
                    </Button>
                </div>
            </div>
        </div>
    );
};

AddDonationWidget.propTypes = {
    callTrackEvent: func.isRequired,
    skus: arrayOf(shape({
        sku_code: string.isRequired,
    })),
    content: shape({
        imageurl: object.isRequired,
    }),
    classes: object.isRequired,
    description: string,
    callDonationItem: func.isRequired,
    pageProcess: func.isRequired,
};

AddDonationWidget.defaultProps = {
    skus: [],
    content: {},
    description: '',
};

const mapStateToProps = (state) => ({
    errorMessage: getDonationError(state),
});

const mapDispatchToProps = (dispatch) => ({
    callDonationItem: bindActionCreators(addDonation, dispatch),
    callTrackEvent: bindActionCreators(trackEvent, dispatch),
});

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(AddDonationWidget);
