/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ButtonBase, Card } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
import {
    object, string, func, shape,
} from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useUIDSeed } from 'react-uid';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { trackEvent as track } from '../../../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import { getFeatureFlags } from '../../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { openPassportSideBar } from '../../../../../../../../state/ducks/Passport/Passport-Action';
import GraphqlIcBannerDetail from '../GraphqlIcBannerDetail';
import { getOperatingSystem } from '../../../../../../../../state/ducks/App/ducks/Config/Config-Helpers';
import cleanText from '../../../../../../../helpers/contentstack/cleanText';
import IcBannerDonationModal from '../IcBannerDonationModal';
import { getActiveABTests } from '../../../../../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';

const useStyles = makeStyles((theme) => ({
    imageCard: {
        width: '100%',
        borderRadius: 0,
        position: 'relative',
        '& h2': {
            position: 'absolute',
            bottom: 0,
            left: 0,
            opacity: 0,
        },
    },
    productImage: {
        display: 'block',
        width: '100%',
        height: '100%',

    },
    productLink: {
        textDecoration: 'none',
    },
    videoContainer: {
        '& video': {
            objectFit: 'cover',
            aspectRatio: '1.89/1',
            boxShadow: '0 2px 4px 0 rgb(0 0 0 / 20%)',
        },
    },
    productMain: {
        position: 'relative',
        '&:hover': {
            '& span': {
                opacity: 1,
                '& button': {
                    opacity: 0.8,
                },
                '& button:hover': {
                    opacity: 1,
                },
            },
        },
    },
    quoteProduct: {
        background: theme.palette.quoteProduct?.background || '#F6F4EF',
        padding: '0 34px 10px',
        '@media screen and (min-width: 500px) and (max-width: 1200px)': {
            padding: '0 20px 10px',
        },
        '@media screen and (min-width: 300px) and (max-width: 500px)': {
            padding: '0 10px 20px',
        },
        '& .quotePrice': {
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '21px',
            letterSpacing: '0.05em',
            '@media screen and (min-width: 300px) and (max-width: 600px)': {
                fontSize: '13px',
                lineHeight: '11px',
            },
        },
    },
    quotationMark: {
        textAlign: 'center',
        fontSize: 70,
        color: '#7F785C',
        fontFamily: 'Garamond, Times, serif',
        fontWeight: 'bold',
        height: 50,
        '@media screen and (min-width: 1050px) and (max-width: 1200px)': {
            lineHeight: '1.1em',
            height: '40px',
        },
        '@media screen and (min-width: 600px) and (max-width: 1050px)': {
            position: 'absolute',
            left: '-6px',
            top: '-18px',
            fontSize: '3em',
        },
        '@media screen and (min-width: 850px) and (max-width: 1050px)': {
            position: 'relative',
            left: 'auto',
            top: 'auto',
            fontSize: 40,
            height: 25,
        },
        '@media screen and (min-width: 300px) and (max-width: 850px)': {
            position: 'relative',
            left: 'auto',
            top: 'auto',
            fontSize: 40,
            height: 25,
        },
    },
    quoteProductImage: {
        boxShadow: 'none',
        background: 'transparent',
        width: '100%',
    },
    quoteTag: {
        margin: '10px 0',
        textTransform: 'uppercase',
        textAlign: 'center',
        color: theme.palette.quoteProduct?.tagLineColor || '#7F785C',
        position: 'relative',
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '21px',
        letterSpacing: '0.05em',
        '@media screen and (min-width: 921px) and (max-width: 1024px)': {
            fontSize: '14px',
        },
        '@media screen and (min-width: 600px) and (max-width: 920px)': {
            fontSize: '11px',
        },
        '@media screen and (min-width: 450px) and (max-width: 600px)': {
            fontSize: '9px',
            margin: '5px 0',
        },
        '@media screen and (min-width: 300px) and (max-width: 450px)': {
            fontSize: '7.5px',
            margin: '5px 0',
        },

        '&:before': {
            width: '10%',
            height: '1px',
            content: "''",
            background: theme.palette.quoteProduct?.tagLineSeparatorColor || '#7F785C',
            left: '0px',
            position: 'absolute',
            top: '50%',
            '@media screen and (min-width: 1099px) and (max-width: 1148px)': {
                width: '10%',
            },
            '@media screen and (min-width: 1025px) and (max-width: 1100px)': {
                width: '5%',
            },
            '@media screen and (min-width: 769px) and (max-width: 1024px)': {
                width: 0,
            },
            '@media screen and (min-width: 600px) and (max-width: 768px)': {
                width: '20%',
            },
            '@media screen and (min-width: 450px) and (max-width: 600px)': {
                width: '10%',
            },
            '@media screen and (min-width: 300px) and (max-width: 450px)': {
                width: '13%',
            },
        },
        '&:after': {
            width: '10%',
            height: '1px',
            content: "''",
            background: theme.palette.quoteProduct?.tagLineSeparatorColor || '#7F785C',
            right: '0px',
            position: 'absolute',
            top: '50%',
            '@media screen and (min-width: 1099px) and (max-width: 1148px)': {
                width: '10%',
            },
            '@media screen and (min-width: 1025px) and (max-width: 1100px)': {
                width: '5%',
            },
            '@media screen and (min-width: 769px) and (max-width: 1024px)': {
                width: 0,
            },
            '@media screen and (min-width: 600px) and (max-width: 768px)': {
                width: '20%',
            },
            '@media screen and (min-width: 450px) and (max-width: 600px)': {
                width: '10%',
            },
            '@media screen and (min-width: 300px) and (max-width: 450px)': {
                width: '13%',
            },
        },
    },
    quoteProductName: {
        fontFamily: '"Canela", Crimson, Garamond, Times, serif',
        fontStyle: 'normal',
        fontWeight: '300',
        fontSize: '24px',
        lineHeight: '30px',
        marginTop: 10,
        '@media screen and (min-width: 601px) and (max-width: 1024px)': {
            fontSize: '24px',
            lineHeight: '1em',
        },
        '@media screen and (min-width: 450px) and (max-width: 600px)': {
            fontSize: '20px',
            lineHeight: '20px',
        },
        '@media screen and (min-width: 300px) and (max-width: 450px)': {
            fontSize: '16px',
            lineHeight: '10px',
        },
    },
    quoteName: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '18px',
        color: theme.palette.quoteProduct?.brandNameColor || 'rgba(0, 0, 0, 0.75)',
        margin: '10px 0',
        '@media screen and (min-width: 601px) and (max-width: 1024px)': {
            fontSize: '14px !important',
            lineHeight: '20px',
        },
        '@media screen and (min-width: 450px) and (max-width: 600px)': {
            fontSize: '10px !important',
            lineHeight: '15px',
        },
        '@media screen and (min-width: 300px) and (max-width: 450px)': {
            fontSize: '8px !important',
            lineHeight: '12px',
        },
    },
    customerDetailsWrapper: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 14,
        '@media screen and (min-width: 601px) and (max-width: 1024px)': {
            fontSize: 14,
        },
        '@media screen and (min-width: 450px) and (max-width: 600px)': {
            fontSize: 10,
        },
        '@media screen and (min-width: 300px) and (max-width: 450px)': {
            fontSize: 7,
        },
    },
    quoteCustomerPhotoWrapper: {
        width: 30,
        height: 32,
        overflow: 'hidden',
        borderRadius: '50%',
        '@media screen and (min-width: 769px) and (max-width: 1024px)': {
            width: 25,
            height: 25,
        },
        '@media screen and (min-width: 601px) and (max-width: 768px)': {
            width: 35,
            height: 35,
        },
        '@media screen and (min-width: 450px) and (max-width: 600px)': {
            width: 25,
            height: 25,
        },
        '@media screen and (min-width: 300px) and (max-width: 450px)': {
            width: 18,
            height: 18,
        },
    },
    quoteCustomerPhoto: {
        width: '100%',
    },
    quoteCustomerName: {
        padding: '0 5px',
        fontSize: 18,
        fontFamily: '"Canela", Crimson, Garamond, Times, serif',
        '@media screen and (min-width: 769px) and (max-width: 1024px)': {
            fontSize: 12,
        },
        '@media screen and (min-width: 601px) and (max-width: 768px)': {
            fontSize: 18,
        },
        '@media screen and (min-width: 450px) and (max-width: 600px)': {
            fontSize: 12,
        },
        '@media screen and (min-width: 300px) and (max-width: 450px)': {
            fontSize: 10,
            textOverflow: 'ellipsis',
            maxWidth: '40px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
    },
    quoteDate: {
        padding: '0 5px',
        fontSize: 12,
        '@media screen and (min-width: 769px) and (max-width: 1024px)': {
            fontSize: 9,
        },
        '@media screen and (min-width: 601px) and (max-width: 768px)': {
            fontSize: 14,
        },
        '@media screen and (min-width: 450px) and (max-width: 600px)': {
            fontSize: 10,
        },
        '@media screen and (min-width: 300px) and (max-width: 450px)': {
            fontSize: 8,
        },
    },
    multiCollectionBannerWrapper: (icBannerBlock) => ({
        width: '100%',
        height: '100%',
        backgroundColor: icBannerBlock?.background_color?.color || '#edf6fc',
        textAlign: 'center',
    }),
    multiCollectionBannerHeader: {
        fontFamily: 'PlayfairDisplay, serif',
        padding: '30px 0 10px',
        fontSize: '24px',
    },
    multiCollectionBannerTileContainer: {
        width: '100%',
        margin: '0 auto',
        paddingBottom: '20px',
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down(600)]: {
            flexWrap: 'wrap',
            justifyContent: 'space-evenly',
        },
    },
    multiCollectionBannerTile: {
        flex: '0 0 24%',
        maxWidth: '24%',
        textDecoration: 'none',
        color: '#000',
        [theme.breakpoints.down(600)]: {
            flex: '0 0 45%',
            maxWidth: '45%',
        },
    },
    multiCollectionBannerTileImage: {
        width: '100%',
        objectFit: 'contain',
    },
    multiCollectionBannerTileCopy: {
        fontSize: '18px',
        [theme.breakpoints.down(600)]: {
            fontSize: '16px',
            marginBottom: '10px',
        },
    },
    detailBtn: {
        bottom: 70,
        display: 'flex',
        zIndex: 9999,
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        height: 30,
        width: 65,
        margin: 'auto',
        textTransform: 'uppercase',
        color: theme.palette.cms?.white || '#FFFFFF',
        borderRadius: 4,
        cursor: 'pointer',
    },
    watchButton: {
        position: 'absolute',
        bottom: '5px',
        left: '0',
        width: '122px',
        background: '#fff',
        margin: '15px',
        height: '34px',
        fontFamily: 'inherit',
        fontWeight: 700,
        fontSize: '16px',
        '& svg': {
            width: '20px',
            height: '20px',
        },
    },
    videoWrapper: {
        position: 'relative',
    },
    videoHeading: {
        '& *': {
            position: 'relative !important',
            fontSize: '20px',
            fontWeight: 700,
            opacity: 'unset !important',
            padding: '8px 0px 3px',
            margin: 0,
            [theme.breakpoints.down('1024')]: {
                fontSize: '18px',
            },
        },
    },
    videoDesc: {
        '& *': {
            fontSize: '16px',
            fontWeight: '400',
            padding: '0px',
            textAlign: 'left',
            margin: 0,
        },
    },
    icBannerDonationButton: {
        padding: 0,
        border: 0,
        cursor: 'pointer',
        width: 'inherit',
    },
}));

const loadImageBlock = (classes, url, title, isFoodBrand, showDonationModal) => {
    if (showDonationModal) {
        return <IcBannerDonationModal data-element="icBanner" url={url} classes={classes} />;
    }
    return (
        <Card data-element="icBanner" className={classes.imageCard}>
            <img
                id={title}
                src={url}
                alt={title}
                className={classes.productImage}
                width="170"
                height="190"
                data-element="icBanner"
            />
            {/* for seo values */}
            {title && isFoodBrand && (<div>{title}</div>)}
        </Card>
    );
};
const addLeadingZero = (digit) => (digit < 10 ? `0${digit}` : digit);

const loadQuoteBlock = (classes, url, title, isFoodBrand, quoteBlock) => {
    const quoteDate = new Date(quoteBlock.date_of_quote);
    const dateOfQuote = `${addLeadingZero(quoteDate.getMonth())}/${addLeadingZero(quoteDate.getDate())}/${quoteDate.getFullYear()}`;
    return (
        <div className={`${classes.productMain} ${classes.quoteProduct}`}>
            <Card className={classes.quoteProductImage}>
                <div className={classes.quotationMark}>“</div>
                <div className={classes.quoteTag}>WHAT CUSTOMERS SAY</div>
                <img
                    src={url}
                    alt={title}
                    className={classes.quoteProductImage}
                />
                {/* for seo values */}
                {title && isFoodBrand && (<div className={classes.quoteProductName}>{title}</div>)}
                <div className={classes.quoteName}>{`"${quoteBlock.customer_quote}"`}</div>
                <div className={classes.customerDetailsWrapper}>
                    <div className={classes.quoteCustomerPhotoWrapper}>
                        <img
                            src={quoteBlock.customer_photo.url}
                            alt={quoteBlock.customer_name}
                            className={classes.quoteCustomerPhoto}
                        />
                    </div>
                    <div className={classes.quoteCustomerName}>{quoteBlock.customer_name}</div>|
                    <div className={classes.quoteDate}>{dateOfQuote}</div>
                </div>
            </Card>
        </div>
    );
};

const IcBanner = ({
    icBannerBlock = {}, presentation_family, trackEvent, passportOpenSideBar, featureFlags, handleOpenPassportModal,
    activeABTests,
}) => {
    const classes = useStyles(icBannerBlock);
    const [open, setOpen] = useState(false);
    const [videoControl, setVideoControl] = useState(false);
    const videoRef = useRef(null);

    const  watchVideo = () => {
        videoRef.current.play();
        setVideoControl(true);
    };
    const operatingSystem = getOperatingSystem().toLowerCase?.();
    const setClose = (id) => {
        setOpen(false);
        if (typeof document !== 'undefined' && document.getElementById(id)) {
            const element = document.getElementById(id);
            // Scroll the window until the position of the Banner is not filled yet
            if (element?.scrollIntoView) {
                setTimeout(() => {
                    element.scrollIntoView();
                }, 300);
            }
        }
    };

    const isMobile =  useMediaQuery('(max-width: 600px)');

    const trackClickEvent = (tile) => {
        const tileBlock = tile || icBannerBlock;
        const { tracking_event_category = '', tracking_event_label = '', tracking_event_action = '' } = tileBlock;
        if (tracking_event_label) {
            trackEvent({
                eventCategory: tracking_event_category,
                eventAction: tracking_event_action,
                eventLabel: tracking_event_label,
            });
        }
    };

    const loadVideoBlock = (url, mobileVideoImage, videoAutoPlay, heading, description) => (
        <div className={classes.imageCard}>
            <div className={classes.videoWrapper}>
                <video
                    ref={videoRef}
                    poster={mobileVideoImage}
                    autoPlay={videoAutoPlay}
                    playsInline
                    controls={videoControl}
                    onClick={() => setVideoControl(true)}
                    loop
                    muted
                    width="100%"
                    height="100%"
                >
                    <source src={`${url}${operatingSystem === 'ios' ? '#t=0.001' : ''}`} type="video/mp4" preload="metadata" />
                    <track kind="captions" />
                </video>
                {!videoControl && url &&   (
                    <ButtonBase
                        className={`${classes.watchButton}`}
                        onClick={() => watchVideo()}
                    >
                        WATCH <PlayArrowIcon className={classes.arrowIcon} />
                    </ButtonBase>
                ) }
            </div>
            {heading &&  <ReactMarkdown source={cleanText(heading)} className={classes.videoHeading} escapeHtml={false} /> }
            {description && <div className={classes.videoDesc}><ReactMarkdown source={description} escapeHtml={false} /> </div>}
        </div>
    );
    const loadVideoV2 = () => {
        const icvid = icBannerBlock.video;
        return (
            <div className={classes.imageCard} data-testid="icvideo">
                <div className={classes.videoWrapper}>
                    <video
                        ref={videoRef}
                        poster={icvid?.poster?.url || ''}
                        autoPlay={icvid?.sound_on ? false : icvid?.autoplay}
                        playsInline
                        controls={icvid?.show_controls}
                        loop
                        muted={!icvid?.sound_on}
                        width="100%"
                        height="100%"
                    >
                        { icvid.video_webm?.url?.length && <source src={`${icvid.video_webm.url}${operatingSystem === 'ios' ? '#t=0.001' : ''}`} type="video/webm" preload="metadata" /> }
                        <source src={`${icvid.video_mp4?.url}${operatingSystem === 'ios' ? '#t=0.001' : ''}`} type="video/mp4" preload="metadata" />
                        <track kind="captions" />
                    </video>
                    {!icvid.show_controls && icvid.video_mp4.url?.length && (
                        <ButtonBase
                            className={`${classes.watchButton}`}
                            onClick={() => watchVideo()}
                        >
                            WATCH <PlayArrowIcon className={classes.arrowIcon} />
                        </ButtonBase>
                    ) }
                </div>
            </div>
        );
    };
    const handleClick = (event, title) => {
        if (title === 'Celebrations Passport') {
            // VARIANT A is "Passport Enrolment Page"
            // CONTROL is "Passport Side Bar"
            /// VARIANT B is "Passport Modal"
            if (activeABTests?.passportVariant === 'passportSideBar' || featureFlags['which-passport-sidebar-enabled-variant']?.variant === 'CONTROL') {
                event.preventDefault();
                passportOpenSideBar();
            }
            if (featureFlags['which-passport-sidebar-enabled-variant']?.variant === 'VARIANT B') {
                event.preventDefault();
                handleOpenPassportModal();
            }
        }
        trackClickEvent();
    };

    const icBannerDetail = (popInfo, detailsButton, title) => {
        const textColor = { color: detailsButton?.details_text_color?.color };
        let textPosition = { right: 0, left: 0 };
        if (detailsButton?.details_button_alignment === 'right') {
            textPosition = { right: 15 };
        }
        if (detailsButton?.details_button_alignment === 'left') {
            textPosition = { left: 15 };
        }
        return (
            <div className={classes.detailBtn} style={textPosition}>
                <span role="presentation" style={textColor} onClick={() => setOpen(true)}>Details</span>
                <GraphqlIcBannerDetail title={title} open={open} setClose={setClose} popInfo={popInfo} />
            </div>
        );
    };

    const isFoodBrand = presentation_family === 'food';

    const loadIcBannerBlock = () => {
        if (icBannerBlock) {
            const showDonationModal = icBannerBlock?.show_donation_modal;
            const popInfo = icBannerBlock?.pop_up_modal_info;
            const showPopUp = icBannerBlock?.show_pop_up_modal;
            const detailsButton = icBannerBlock?.details_button;
            const href = icBannerBlock.link?.href;
            const title = icBannerBlock.link?.title;
            const heading = icBannerBlock?.heading;
            const description = icBannerBlock?.description;
            const url = (isMobile && icBannerBlock?.image_for_mobile) ? `${icBannerBlock.image_for_mobile?.url}?auto=webp` : `${icBannerBlock.image?.url}?auto=webp`;            const contentType = ((isMobile && icBannerBlock?.image_for_mobile) ? icBannerBlock.image_for_mobile?.content_type : icBannerBlock.image?.content_type) || null;
            const mobileVideoImage = isMobile ? icBannerBlock?.mobile_video_image?.url : '';
            const videoAutoPlay = isMobile && icBannerBlock?.auto_play;
            const seed = useUIDSeed();
            const hasVideoV2 = icBannerBlock.video?.video_mp4?.url?.length || icBannerBlock.video?.video_webm?.url?.length;
            if (icBannerBlock.is_multiple_collections_banner) {
                const desktopCollectionTiles = icBannerBlock.collection_tiles_content?.slice();
                if (!isMobile) {
                    [desktopCollectionTiles[1], desktopCollectionTiles[2]] = [desktopCollectionTiles[2], desktopCollectionTiles[1]];
                }
                return (
                    <div className={classes.multiCollectionBannerWrapper}>
                        <div className={classes.multiCollectionBannerHeader}>{icBannerBlock.header}</div>
                        <div className={classes.multiCollectionBannerTileContainer}>
                            {isMobile ? (icBannerBlock.collection_tiles_content?.map((elem) => (
                                <Link key={seed(elem)} to={elem.tile?.link?.href} className={classes.multiCollectionBannerTile} onClick={() => trackClickEvent(elem.tile)}>
                                    <img
                                        className={classes.multiCollectionBannerTileImage}
                                        src={elem.tile?.image?.url}
                                        alt={elem.tile?.link?.title}
                                    />
                                    <div className={classes.multiCollectionBannerTileCopy}>{elem.tile?.link?.title}</div>
                                </Link>
                            ))) : (desktopCollectionTiles.map((elem) => (
                                <Link key={seed(elem)} to={elem.tile?.link?.href} className={classes.multiCollectionBannerTile} onClick={() => trackClickEvent(elem.tile)}>
                                    <img
                                        className={classes.multiCollectionBannerTileImage}
                                        src={elem.tile?.image?.url}
                                        alt={elem.tile?.link?.title}
                                    />
                                    <div className={classes.multiCollectionBannerTileCopy}>{elem.tile?.link?.title}</div>
                                </Link>
                            )))}
                        </div>
                    </div>
                );
            }
            if (hasVideoV2) {
                return loadVideoV2();
            }
            switch (contentType) {
                case 'image/jpeg':
                case 'image/png':
                case 'image/gif':
                    if (href?.indexOf('http://') === 0 || href?.indexOf('https://') === 0 || href?.includes('/blog') || href?.includes('/articles')) {
                        return (
                            <>
                                <a aria-hidden="true" tabIndex="-1" href={href} className={classes.productLink} onClick={(event) => handleClick(event, title)} target="_blank" rel="noreferrer">
                                    {loadImageBlock(classes, url, title, isFoodBrand)}
                                </a>
                                {showPopUp && icBannerDetail(popInfo, detailsButton, title)}
                            </>
                        );
                    }
                    if (href) {
                        return (
                            <>
                                <Link aria-hidden="true" tabIndex="-1" to={href} title={title} className={classes.productLink} onClick={(event) => handleClick(event, title)}>
                                    {loadImageBlock(classes, url, title, isFoodBrand)}
                                </Link>
                                {showPopUp && icBannerDetail(popInfo, detailsButton, title)}
                            </>
                        );
                    } return (
                        url
                            ? (
                                <>
                                    {loadImageBlock(classes, url, title, isFoodBrand, showDonationModal)}
                                    {showPopUp && icBannerDetail(popInfo, detailsButton, title)}
                                </>
                            ) : null
                    );
                case ('video/mp4' && !hasVideoV2):
                    return (
                        (href) ? (
                            <div className={classes.videoContainer}>
                                <Link to={href} title={title} className={classes.productLink} onClick={(event) => handleClick(event, title)}>
                                    {loadVideoBlock(url, mobileVideoImage, videoAutoPlay, heading, description)}
                                </Link>
                            </div>
                        )
                            : (
                                <div className={classes.videoContainer}>
                                    {loadVideoBlock(url, mobileVideoImage, videoAutoPlay, heading, description)}
                                </div>
                            )
                    );
                default:
                    return null;
            }
        } return null;
    };
    const loadCustomerQuoteBlock = () => {
        if (icBannerBlock) {
            const href = icBannerBlock.link?.href;
            const title = icBannerBlock.link?.title;
            const url = icBannerBlock.image?.url;
            const contentType = icBannerBlock.image?.content_type || null;
            switch (contentType) {
                case 'image/jpeg':
                case 'image/png':
                case 'image/gif':
                    if (href?.indexOf('http://') === 0 || href?.indexOf('https://') === 0 || href?.includes('/blog') || href?.includes('/articles')) {
                        return (
                            <a href={href} className={classes.productLink} onClick={(event) => handleClick(event, title)} target="_blank" rel="noreferrer">
                                {loadQuoteBlock(classes, url, title, isFoodBrand, icBannerBlock)}
                            </a>
                        );
                    }
                    if (href) {
                        return (
                            <Link to={href} title={title} className={classes.productLink} onClick={(event) => handleClick(event, title)}>
                                {loadQuoteBlock(classes, url, title, isFoodBrand, icBannerBlock)}
                            </Link>
                        );
                    } return (
                        url
                            ? (
                                loadQuoteBlock(classes, url, title, isFoodBrand, icBannerBlock)
                            ) : null
                    );
                default:
                    return null;
            }
        } return null;
    };

    if (icBannerBlock.presentation_type === 'Customer Quote') {
        return loadCustomerQuoteBlock();
    }
    return loadIcBannerBlock();
};
IcBanner.propTypes = {
    icBannerBlock: object,
    activeABTests: object.isRequired,
    presentation_family: string.isRequired,
    trackEvent: func.isRequired,
    featureFlags: shape({
        'which-passport-sidebar-enabled-variant': {
            variant: string,
        },
    }),
    passportOpenSideBar: func,
    handleOpenPassportModal: func,
};
IcBanner.defaultProps = {
    icBannerBlock: {},
    featureFlags: {},
    passportOpenSideBar: () => { },
    handleOpenPassportModal: () => { },
};

const mapStateToProps = (state) => ({
    featureFlags: getFeatureFlags(state),
    activeABTests: getActiveABTests(state),
});

const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(track, dispatch),
    passportOpenSideBar: bindActionCreators(openPassportSideBar, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(IcBanner);
