/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { withRouter } from 'react-router';
import {
    bool, func, object, string,
} from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { useQuery } from '@apollo/client';
import mbpLogger from 'mbp-logger';
import ReactMarkdown from 'react-markdown';
import DonationItem from './DonationItem';
import { getDonationItem } from '../../../../../../../../state/ducks/Common/Common-Selectors';
import { trackEvent } from '../../../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import { getFeatureFlags } from '../../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getActiveABTest } from '../../../../../../../../state/ducks/Checkout/ducks/App/App-Selectors';
import findDonationUrl from '../../../../../../../gql/queries/findDonationUrl';
import { GRAPHQL_ENV } from '../../../../../../../gql';

const useStyles = makeStyles({
    donationSubmittedContainer: {
        display: 'flex',
        '& img': {
            height: '311px',
            width: '284px',
            backgroundColor: '#CCC',
            marginRight: '24px',
            '@media screen and (max-width: 700px)': {
                width: 'auto',
                height: '120px',
            },
        },
    },
    donationContentContainer: {
        '& h2': {
            marginTop: 0,
        },
        '& p': {
            fontSize: '15px',
        },
    },
});

const getActiveDonationCampaign = ({ featureFlags }) => {
    let url = '/glaad';
    if (
        featureFlags?.['ic-banner-donation-modal'] !== 'no-campaign') {
        url = `/${featureFlags?.['ic-banner-donation-modal']}`;
    }
    return url;
};

const DonationBuilder = ({
    callTrackEvent, featureFlags, addBorder, pageProcess,
}) => {
    const findContentOptions = {
        brand: 'global',
        environment: GRAPHQL_ENV,
        contentType: 'donations',
        locale: 'en-us',
        url: getActiveDonationCampaign({ featureFlags }),
    };
    const DONATION_QUERY = findDonationUrl(findContentOptions);

    const classes = useStyles();
    const donationItem = useSelector(getDonationItem);
    const activeDonation = featureFlags['ic-banner-donation-modal'];
    const donationAbTest = useSelector(getActiveABTest('donationCopy'));
    useEffect(() => {
        if (activeDonation !== 'no-campaign') {
            callTrackEvent({
                eventCategory: 'Collection Page Modal',
                eventAction: 'Product Impression',
                eventLabel: 'Donations',
                queued: true,
            });
        }
    }, []);

    const { loading, error, data } = useQuery(DONATION_QUERY);
    if (loading && !data && !error) {
        return null;
    }

    if (error) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            query: DONATION_QUERY,
            component: 'DonationBuilder.js',
            message: 'Error loading data from Graphql',
            env: GRAPHQL_ENV,
            error,
        });
        return null;
    }

    if (!data) {
        mbpLogger.logWarning({
            appName: process.env.npm_package_name,
            query: DONATION_QUERY,
            component: 'DonationBuilder.js',
            message: 'No data returned for query',
            env: GRAPHQL_ENV,
        });
        return null;
    }

    if (donationItem.length > 0) {
        const content = data?.findContentPage?.content?.entries?.[0];
        let image = content?.after_selection?.image?.url || 'https://images.contentstack.io/v3/assets/blt95e0138311135214/bltddcc78a1289aa78e/6214f83d0886043ab2c672d2/192399x.jpeg';
        let copy = content?.after_selection?.copy || '## Thankyou! \n\n Your donation has been added to your cart.';

        if (donationAbTest?.type === 'variant') {
            image = donationAbTest?.img || image;
            copy = donationAbTest?.copy || copy;
        }

        return (
            <div className={classes.donationSubmittedContainer}>
                <div className={classes.donationContentContainer}>
                    <img
                        alt={activeDonation  || 'Donation logo'}
                        src={image}
                    />
                </div>
                <div className={classes.donationContentContainer}>
                    <ReactMarkdown source={copy} escapeHtml={false} />
                </div>
            </div>
        );
    }

    return (activeDonation !== 'no-campaign' && donationItem.length === 0 ? (
        <DonationItem
            pageProcess={pageProcess}
            addBorder={addBorder}
            featureFlags={featureFlags}
            data={data}
        />
    )
        : <><h2>Sorry but there seems to be an issue</h2><p>Please close and retry. We apologize for an inconvenience.</p></>
    );
};

DonationBuilder.propTypes = {
    addBorder: bool,
    callTrackEvent: func.isRequired,
    featureFlags: object.isRequired,
    pageProcess: string.isRequired,
};

DonationBuilder.defaultProps = {
    addBorder: false,
};

const mapStateToProps = (state) => ({
    featureFlags: getFeatureFlags(state),
});

const mapDispatchToProps = (dispatch) => ({
    callTrackEvent: bindActionCreators(trackEvent, dispatch),
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
)(DonationBuilder);
