/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { gql } from '@apollo/client';
import { GRAPHQL_ENV } from '..';

/**
 * @param {string} brand - domain name: brand['domain-name']
 * @param {string} collectionId - - category to be referenced
 * @param {string} numberOfProducts - number of products to return, default entire collection.
 */

const findCollectionById = (brand, collectionId, numberOfProducts) => (
    gql`{
        findCollectionById(brand: "${brand}", locale: "en-us", environment: "${GRAPHQL_ENV}", id: "${collectionId}", productOptions: {page: 1, pageSize: ${numberOfProducts}}) {
         id
         brand
             products{
                 name
                 partNumber
                 prices{ type, value },             
                 skuPriceRange{ sale{ value }, retail{ value } },
                 productType
                 id
                 isCodified
                 image{
                 name
                 path
                 snipe
                 snipeImagePath
                 altText
                 }
                 seo{
                 url
                 }
                 productSkus{
                 partNumber
                 }
             }
         totalProducts
         totalPages
     }
   }`
);
export default findCollectionById;
