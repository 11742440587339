/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { useQuery } from '@apollo/client';
import { decodeHTML } from 'entities';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import mbpLogger from 'mbp-logger';
import {
    number, string,
} from 'prop-types';
import { GRAPHQL_ENV } from '../../../../../../../../gql';
import SubCollecttionV2Skeleton from '../../../../../../GraphqlSkeletonComponents/SubCollecttionV2Skeleton';
import MobileSubCollecttionV2Skeleton from '../../../../../../GraphqlSkeletonComponents/MobileSubCollecttionV2Skeleton';
import IcSubcollectionSliderUI from './IcSubcollectionSliderUI';
import findCollectionById from '../../../../../../../../gql/queries/findCollectionById';

const  IcSubCollectionV2Conatiner = ({
    brand, collectionId, pageSize, variant,
}) => {
    // rendered the sekelton based on desktop and mobile
    const matches = useMediaQuery('(min-width: 599px)');
    const renderSkeleton = matches ? <SubCollecttionV2Skeleton countBlock={2} width="50%" /> : <MobileSubCollecttionV2Skeleton />;

    const FINDCOLLECTION_BY_ID = findCollectionById(brand, collectionId, pageSize);

    const { data, error, loading } = useQuery(FINDCOLLECTION_BY_ID);
    if (loading) {
        return renderSkeleton;
    }

    if (error) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            query: FINDCOLLECTION_BY_ID,
            component: ' IcSubCollectionV2Conatiner.js',
            message: 'Error loading data from Graphql',
            env: GRAPHQL_ENV,
            error,
        });
        return null;
    }
    if (!data?.findCollectionById || !data?.findCollectionById?.products) {
        mbpLogger.logWarning({
            appName: process.env.npm_package_name,
            query: FINDCOLLECTION_BY_ID,
            component: ' IcSubCollectionV2Conatiner.js',
            message: 'No product returned for query',
            env: GRAPHQL_ENV,
        });
        return null;
    }

    const subcollectionProductData = data?.findCollectionById?.products || null;

    const products = [];
    subcollectionProductData.forEach((product) => {
        const productData = { ...product };
        productData.name = decodeHTML(productData.name);
        if (productData?.image?.length) {
            // adding url attribute to image by combining image.path and image.name
            if (productData.image.path && productData.image.name) {
                productData.image.url = [productData.image.path, productData.image.name, 'c.jpg'].join('');
            } else {
                productData.image.url = '';
            }
        }
        products.push(productData);
    });

    if (products && products.length > 0) {
        return <IcSubcollectionSliderUI  productData={products} variantStyle={variant} />;
    }
    return null;
};

IcSubCollectionV2Conatiner.propTypes = {
    brand: string.isRequired,
    collectionId: string.isRequired,
    pageSize: number.isRequired,
    variant: string,
};

IcSubCollectionV2Conatiner.defaultProps = {
    variant: 'default',
};

export default IcSubCollectionV2Conatiner;
