/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    number,
    shape, string,
} from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { makeStyles } from '@material-ui/core/styles';
import IcSubCollectionV2Container from './IcSubCollectionV2Container';

// strips "font-family:" and ";" from value
const parseFont = (font) => font?.match(/:\s(.*?);/, '')?.[1] || null;

const themes = {
    mainContiner: {
        default: {
            clip: 'url(#subcollectionGroup)',
            width: '98%',
            height: '64%',
            zIndex: '-1',
            position: 'absolute',
        },
        variant_a: {
            borderRadius: '8px',
            padding: '0px 30px',
        },
    },
    mainHeadingConatiner: {
        default: {
            fontSize: '2.6em',
            paddingTop: 10,
        },
        variant_a: {
            fontSize: '1.6em',
            paddingTop: 25,
            mobileLeftPadding: '5px',
        },
    },
};

const useStyles = makeStyles((theme) => ({
    IcSubcollectionContainer: (styles) => ({
        backgroundColor: styles?.presentation_style === 'variant_a' ? styles?.color_background?.color : '',
        borderRadius: themes?.mainContiner[styles?.presentation_style]?.borderRadius,
        padding: themes?.mainContiner[styles?.presentation_style]?.padding || '',
    }),
    backgroundFiller: (styles) => ({
        backgroundColor: styles?.color_background?.color || '#EAF1ED',
        clipPath: themes?.mainContiner[styles?.presentation_style]?.clip,
        width: themes?.mainContiner[styles?.presentation_style]?.width,
        height: themes?.mainContiner[styles?.presentation_style]?.height,
        zIndex: themes?.mainContiner[styles?.presentation_style]?.zIndex,
        position: themes?.mainContiner[styles?.presentation_style]?.position,
    }),
    svgTear: {
        width: 0,
        height: 0,
        position: 'absolute',
    },
    mainHeading: (styles) => ({
        fontSize: themes?.mainHeadingConatiner[styles?.presentation_style]?.fontSize || '2.6em',
        fontFamily: parseFont(styles.heading?.font),
        color: styles?.color_copy?.color || '#000',
        paddingTop: themes?.mainHeadingConatiner[styles?.presentation_style]?.paddingTop || 10,
        paddingLeft: 15,
        '& p': {
            margin: 0,
        },
        [theme.breakpoints.down(1025)]: {
            paddingTop: 20,
        },
        [theme.breakpoints.down(601)]: {
            fontSize: themes?.mainHeadingConatiner[styles?.presentation_style]?.fontSize || '2.6em',
            paddingLeft: themes?.mainHeadingConatiner[styles?.presentation_style]?.mobileLeftPadding || '35px',
            lineHeight: 1.2,
            paddingTop: '15px',
        },
    }),
    subHeading: (styles) => ({
        fontSize: '.8em',
        fontFamily: parseFont(styles.sub_heading?.font),
        color: styles?.color_copy?.color || '#000',
        fontWeight: 'bold',
        paddingLeft: '15px',
        [theme.breakpoints.down(1025)]: {
            '& p': {
                margin: '5px 0',
                width: '350px',
            },
        },
        [theme.breakpoints.down(1126)]: {
            '& p': {
                margin: '5px 0',
                width: '330px',
            },
        },
        [theme.breakpoints.down(726)]: {
            fontSize: '1em',
            '& p': {
                margin: '5px 0',
                width: '270px',
            },
        },
        [theme.breakpoints.down(601)]: {
            fontWeight: 'normal',
            fontSize: '0.8em',
            width: 'auto',
            paddingLeft: '35px',
            '& p': {
                margin: '12px 0',
                width: 'auto',
            },
        },
    }),

}));

const IcSubcollectionHeader = ({
    subcollectionData,
}) => {
    const variantA = subcollectionData?.presentation_style === 'variant_a';
    const classes = useStyles(subcollectionData, variantA);

    const brandName = subcollectionData?.subcollection_definitions?.parent_brand;
    const collectionId = subcollectionData?.subcollection_definitions?.collection_id;
    const numberOfProducts = subcollectionData?.subcollection_definitions?.page_size;

    // render the clipPath for subcollection
    const renderSvgClipPath = () => (
        <svg className={classes.svgTear}>
            <clipPath id="subcollectionGroup" clipPathUnits="objectBoundingBox"><path d="M0.025,0.992 C0.017,0.993,0.008,0.997,0,1 L0,0 L1,0 L1,0.996 C0.996,0.994,1,0.984,0.998,0.99 C0.997,0.991,0.993,0.99,0.993,0.99 C0.987,0.99,0.982,0.983,0.976,0.983 C0.965,0.982,0.953,0.979,0.942,0.979 C0.932,0.979,0.921,0.985,0.911,0.985 C0.908,0.985,0.906,0.985,0.903,0.981 C0.902,0.98,0.901,0.979,0.9,0.979 C0.897,0.979,0.894,0.982,0.891,0.985 C0.884,0.994,0.873,0.992,0.865,0.992 C0.859,0.993,0.853,0.994,0.847,0.992 C0.841,0.99,0.835,0.989,0.829,0.993 C0.823,0.998,0.818,0.99,0.812,0.989 C0.803,0.987,0.794,0.987,0.785,0.987 C0.782,0.987,0.779,0.983,0.776,0.981 C0.772,0.979,0.77,0.986,0.767,0.987 C0.759,0.987,0.751,0.991,0.743,0.987 C0.735,0.984,0.728,0.983,0.72,0.983 C0.714,0.983,0.709,0.979,0.702,0.982 C0.696,0.986,0.689,0.989,0.683,0.995 C0.678,0.999,0.672,0.996,0.667,0.994 C0.654,0.99,0.64,0.993,0.626,0.987 C0.622,0.985,0.617,0.985,0.613,0.98 C0.609,0.975,0.604,0.979,0.6,0.979 C0.584,0.979,0.569,0.987,0.553,0.987 C0.545,0.987,0.537,0.989,0.529,0.991 C0.516,0.993,0.502,0.987,0.488,0.987 C0.471,0.987,0.454,0.982,0.436,0.986 C0.429,0.988,0.419,0.983,0.412,0.991 C0.409,0.995,0.402,0.99,0.399,0.99 C0.38,0.99,0.361,0.984,0.342,0.996 C0.334,1,0.326,1,0.317,0.997 C0.31,0.994,0.304,0.989,0.297,0.991 C0.286,0.993,0.275,0.991,0.265,0.988 C0.256,0.985,0.247,0.986,0.238,0.982 C0.231,0.98,0.224,0.983,0.217,0.985 C0.205,0.989,0.193,0.988,0.182,0.991 C0.169,0.993,0.157,0.994,0.145,0.996 C0.135,0.997,0.125,0.995,0.115,0.991 C0.104,0.987,0.092,0.992,0.08,0.995 C0.071,0.997,0.062,0.998,0.052,0.998 C0.043,0.998,0.034,0.992,0.025,0.992" /></clipPath>
        </svg>
    );

    return (
        <div className={classes.IcSubcollectionContainer}>
            <div className={classes.backgroundFiller}>
                {renderSvgClipPath()}
            </div>
            <div className={classes.subcollectionGrid}>
                <ReactMarkdown
                    className={classes.mainHeading}
                    source={subcollectionData?.heading?.heading}
                    escapeHtml={false}
                />
                {!variantA
                && (
                    <ReactMarkdown
                        className={classes.subHeading}
                        source={subcollectionData?.sub_heading?.sub_heading}
                        escapeHtml={false}
                    />
                )}
            </div>
            <IcSubCollectionV2Container
                brand={brandName}
                collectionId={collectionId}
                pageSize={numberOfProducts}
                variant={subcollectionData?.presentation_style}
            />
        </div>
    );
};

IcSubcollectionHeader.propTypes = {
    subcollectionData: shape({
        title: string,
        presentation_style: string,
        color_background: shape({
            color: string,
        }),
        color_copy: shape({
            color: string,
        }),
        heading: shape({
            font: string,
            heading: string,
        }),
        subcollection_definitions: shape({
            collection_id: string,
            page_size: number,
            parent_brand: string,

        }),
    }),
};

IcSubcollectionHeader.defaultProps = {
    subcollectionData: {},
};

export default IcSubcollectionHeader;
